import React from 'react'
import * as S from './styles'

const Menu = () => (
  <S.MenuWrapper>
    <S.MenuGroup>
      <h2 style={{ marginBottom: 'var(--xs)' }}>ESPRESSO + MILK</h2>
      <ul>
        <li>
          <h3>Caffe Latte</h3>
          <ul>
            <li>
              SM <span>$3.60</span>
            </li>
            <li>
              MD <span>$4.10</span>
            </li>
            <li>
              LG <span>$4.60</span>
            </li>
          </ul>
        </li>
        <li>
          <h3>Vanilla Latte</h3>
          <ul>
            <li>
              SM <span>$3.75</span>
            </li>
            <li>
              MD <span>$4.25</span>
            </li>
            <li>
              LG <span>$4.75</span>
            </li>
          </ul>
        </li>
        <li>
          <h3>Mocha</h3>
          <ul>
            <li>
              SM <span>$3.75</span>
            </li>
            <li>
              MD <span>$4.25</span>
            </li>
            <li>
              LG <span>$4.75</span>
            </li>
          </ul>
        </li>
        <li>
          <h3>White Chocolate Latte</h3>
          <ul>
            <li>
              SM <span>$3.75</span>
            </li>
            <li>
              MD <span>$4.25</span>
            </li>
            <li>
              LG <span>$4.75</span>
            </li>
          </ul>
        </li>
        <li>
          <h3>Carmel Macchiato</h3>
          <ul>
            <li>
              SM <span>$3.75</span>
            </li>
            <li>
              MD <span>$4.25</span>
            </li>
            <li>
              LG <span>$4.75</span>
            </li>
          </ul>
        </li>
        <li>
          <h3>Cappuccino</h3>
          <ul>
            <li>
              SM <span>$3.60</span>
            </li>
            <li>
              MD <span>$4.10</span>
            </li>
            <li>
              LG <span>$4.60</span>
            </li>
          </ul>
        </li>
        <li>
          <h3>Americano</h3>
          <p>
            <strong>Espresso + hot water</strong>
          </p>
          <ul>
            <li>
              SM <span>$2.50</span>
            </li>
            <li>
              MD <span>$3.00</span>
            </li>
            <li>
              LG <span>$3.50</span>
            </li>
          </ul>
        </li>
      </ul>
    </S.MenuGroup>
    <S.MenuGroup>
      <h2 style={{ marginBottom: 'var(--xs)' }}>ESPRESSO</h2>
      <p>All items are less than 4oz.</p>
      <ul>
        <li>
          <h3>Doppio Espresso</h3>
          <p>Just two shots</p>
          <ul>
            <li>
              <span>$1.50</span>
            </li>
          </ul>
        </li>
        <li>
          <h3>Cortado</h3>
          <p>Espresso + hot milk</p>
          <ul>
            <li>
              <span>$2.00</span>
            </li>
          </ul>
        </li>
        <li>
          <h3>Con Panna</h3>
          <p>Espresso + whip</p>
          <ul>
            <li>
              <span>$1.75</span>
            </li>
          </ul>
        </li>
        <li>
          <h3>Espresso Macchiato</h3>
          <p>Espresso + foam</p>
          <ul>
            <li>
              <span>$1.75</span>
            </li>
          </ul>
        </li>
        <li>
          <h3>Under Tow</h3>
          <p>Espresso + sweet cream</p>
          <ul>
            <li>
              <span>$2.00</span>
            </li>
          </ul>
        </li>
        <li>
          <h3>Affagato</h3>
          <p>Espresso + ice cream</p>
          <ul>
            <li>
              <span>$3.00</span>
            </li>
          </ul>
        </li>
        <li>
          <h3>Martini Espresso</h3>
          <p>Espresso shaken with ice</p>
          <ul>
            <li>
              <span>$2.25</span>
            </li>
          </ul>
        </li>
      </ul>
    </S.MenuGroup>
    <S.MenuGroup>
      <h2 style={{ marginBottom: 'var(--xs)' }}>Coffee</h2>
      <ul>
        <li>
          <h3>Traditional Coffee</h3>
          <ul>
            <li>
              SM <span>$2.05</span>
            </li>
            <li>
              MD <span>$2.15</span>
            </li>
            <li>
              LG <span>$2.45</span>
            </li>
          </ul>
        </li>
        <li>
          <h3>Pour Over</h3>
          <ul>
            <li>
              SM <span>$2.15</span>
            </li>
            <li>
              MD <span>$2.25</span>
            </li>
            <li>
              LG <span>$2.55</span>
            </li>
          </ul>
        </li>
        <li>
          <h3>Cold Brew</h3>
          <ul>
            <li>
              SM <span>$3.25</span>
            </li>
            <li>
              MD <span>$3.75</span>
            </li>
            <li>
              LG <span>$3.95</span>
            </li>
          </ul>
        </li>
        <li>
          <h3>French Press</h3>
          <ul>
            <li>
              SM <span>$2.15</span>
            </li>
            <li>
              MD <span>$2.25</span>
            </li>
            <li>
              LG <span>$2.55</span>
            </li>
          </ul>
        </li>
        <li>
          <h3>Cafe Au Lait</h3>
          <ul>
            <li>
              SM <span>$2.75</span>
            </li>
            <li>
              MD <span>$2.85</span>
            </li>
            <li>
              LG <span>$3.15</span>
            </li>
          </ul>
        </li>
      </ul>
    </S.MenuGroup>
    <S.MenuGroup>
      <h2 style={{ marginBottom: 'var(--xs)' }}>Tea, etc.</h2>
      <ul>
        <li>
          <h3>Cup of Tea</h3>
          <ul>
            <li>
              SM <span>$2.25</span>
            </li>
            <li>
              MD <span>$2.45</span>
            </li>
            <li>
              LG <span>$2.65</span>
            </li>
          </ul>
        </li>
        <li>
          <h3>Chai Latte</h3>
          <ul>
            <li>
              SM <span>$3.40</span>
            </li>
            <li>
              MD <span>$3.85</span>
            </li>
            <li>
              LG <span>$4.30</span>
            </li>
          </ul>
        </li>
        <li>
          <h3>London Fog</h3>
          <ul>
            <li>
              SM <span>$3.40</span>
            </li>
            <li>
              MD <span>$3.85</span>
            </li>
            <li>
              LG <span>$4.30</span>
            </li>
          </ul>
        </li>
        <li>
          <h3>Matcha Latte</h3>
          <ul>
            <li>
              SM <span>$3.75</span>
            </li>
            <li>
              MD <span>$3.95</span>
            </li>
            <li>
              LG <span>$4.45</span>
            </li>
          </ul>
        </li>
        <li>
          <h3>Hot Chocolate</h3>
          <ul>
            <li>
              SM <span>$2.75</span>
            </li>
            <li>
              MD <span>$2.95</span>
            </li>
            <li>
              LG <span>$3.45</span>
            </li>
          </ul>
        </li>
        <li>
          <h3>Chocolate Milk</h3>
          <ul>
            <li>
              SM <span>$2.25</span>
            </li>
            <li>
              MD <span>$2.45</span>
            </li>
            <li>
              LG <span>$2.65</span>
            </li>
          </ul>
        </li>
        <li>
          <h3>Steamer</h3>
          <ul>
            <li>
              SM <span>$2.25</span>
            </li>
            <li>
              MD <span>$2.45</span>
            </li>
            <li>
              LG <span>$2.65</span>
            </li>
          </ul>
        </li>
      </ul>
    </S.MenuGroup>
    <S.MenuGroup>
      <h2 style={{ marginBottom: 'var(--xs)' }}>Java Shakes</h2>
      <ul>
        <li>
          <h3>Chocolate</h3>
          <ul>
            <li>
              SM <span>$4.25</span>
            </li>
            <li>
              MD <span>$4.95</span>
            </li>
            <li>
              LG <span>$5.25</span>
            </li>
          </ul>
        </li>
        <li>
          <h3>Vanilla</h3>
          <ul>
            <li>
              SM <span>$4.25</span>
            </li>
            <li>
              MD <span>$4.95</span>
            </li>
            <li>
              LG <span>$5.25</span>
            </li>
          </ul>
        </li>
        <li>
          <h3>Caramel</h3>
          <ul>
            <li>
              SM <span>$4.25</span>
            </li>
            <li>
              MD <span>$4.95</span>
            </li>
            <li>
              LG <span>$5.25</span>
            </li>
          </ul>
        </li>
        <li>
          <h3>White Chocolate</h3>
          <ul>
            <li>
              SM <span>$4.25</span>
            </li>
            <li>
              MD <span>$4.95</span>
            </li>
            <li>
              LG <span>$5.25</span>
            </li>
          </ul>
        </li>
      </ul>
    </S.MenuGroup>
    <S.MenuGroup>
      <h2 style={{ marginBottom: 'var(--xs)' }}>Fruit Smoothies</h2>
      <p>Pick two flavors, mix and match.</p>
      <ul>
        <li>
          <h3>Strawberry</h3>
          <ul>
            <li>
              SM <span>$4.50</span>
            </li>
            <li>
              MD <span>$5.00</span>
            </li>
            <li>
              LG <span>$5.50</span>
            </li>
          </ul>
        </li>
        <li>
          <h3>Banana</h3>
          <ul>
            <li>
              SM <span>$4.50</span>
            </li>
            <li>
              MD <span>$5.00</span>
            </li>
            <li>
              LG <span>$5.50</span>
            </li>
          </ul>
        </li>
        <li>
          <h3>Peach</h3>
          <ul>
            <li>
              SM <span>$4.50</span>
            </li>
            <li>
              MD <span>$5.00</span>
            </li>
            <li>
              LG <span>$5.50</span>
            </li>
          </ul>
        </li>
        <li>
          <h3>Mango</h3>
          <ul>
            <li>
              SM <span>$4.50</span>
            </li>
            <li>
              MD <span>$5.00</span>
            </li>
            <li>
              LG <span>$5.50</span>
            </li>
          </ul>
        </li>
        <li>
          <h3>Pineapple Coconut</h3>
          <ul>
            <li>
              SM <span>$4.50</span>
            </li>
            <li>
              MD <span>$5.00</span>
            </li>
            <li>
              LG <span>$5.50</span>
            </li>
          </ul>
        </li>
        <li>
          <h3>Raspberry Strawberry</h3>
          <ul>
            <li>
              SM <span>$4.50</span>
            </li>
            <li>
              MD <span>$5.00</span>
            </li>
            <li>
              LG <span>$5.50</span>
            </li>
          </ul>
        </li>
        <li>
          <h3>Paypaya Guava</h3>
          <ul>
            <li>
              SM <span>$4.50</span>
            </li>
            <li>
              MD <span>$5.00</span>
            </li>
            <li>
              LG <span>$5.50</span>
            </li>
          </ul>
        </li>
        <li>
          <h3>Pomegranate Blueberry</h3>
          <ul>
            <li>
              SM <span>$4.50</span>
            </li>
            <li>
              MD <span>$5.00</span>
            </li>
            <li>
              LG <span>$5.50</span>
            </li>
          </ul>
        </li>
      </ul>
    </S.MenuGroup>
    <S.MenuGroup>
      <h2>Soft Serve Ice Cream</h2>
      <ul>
        <li>
          <h3>Vanilla</h3>
          <ul>
            <li>
              <span>$3.00</span>
            </li>
          </ul>
        </li>
      </ul>
    </S.MenuGroup>
    <S.MenuGroup>
      <h2 style={{ marginBottom: 'var(--xs)' }}>Bubbles</h2>
      <ul>
        <li>
          <h3>Italian Soda</h3>
          <ul>
            <li>
              SM <span>$2.55</span>
            </li>
            <li>
              MD <span>$2.75</span>
            </li>
            <li>
              LG <span>$2.95</span>
            </li>
          </ul>
        </li>
        <li>
          <h3>Root Beer Float</h3>
          <ul>
            <li>
              SM <span>$3.15</span>
            </li>
            <li>
              MD <span>$3.30</span>
            </li>
            <li>
              LG <span>$3.45</span>
            </li>
          </ul>
        </li>
        <li>
          <h3>Topo Chico (Bottled)</h3>
          <ul>
            <li>
              <span>$2.00</span>
            </li>
          </ul>
        </li>
      </ul>
    </S.MenuGroup>
    <S.MenuGroup>
      <h2>Specs</h2>
      <ul>
        <li>
          <h3>Add a shot of espresso</h3>
          <ul>
            <li>
              <span>$0.75</span>
            </li>
          </ul>
        </li>
        <li>
          <h3>Add Syrup</h3>
          <ul>
            <li>
              <span>$0.25</span>
            </li>
          </ul>
        </li>
        <li>
          <h3>Sub Coconut Milk</h3>
          <ul>
            <li>
              <span>$0.50</span>
            </li>
          </ul>
        </li>
        <li>
          <h3>Sub Almond Milk</h3>
          <ul>
            <li>
              <span>$0.50</span>
            </li>
          </ul>
        </li>
        <li>
          <h3>Sub Oat Milk</h3>
          <ul>
            <li>
              <span>$0.50</span>
            </li>
          </ul>
        </li>
        <li>
          <h3>Sub Nonfat Milk</h3>
          <ul>
            <li>
              <span>Free</span>
            </li>
          </ul>
        </li>
      </ul>
    </S.MenuGroup>
  </S.MenuWrapper>
)

export default Menu
