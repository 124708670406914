import styled from '@emotion/styled'
import { queries } from '../../theme/globalStyles'

export const MenuWrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--xl);

  ${queries[2]} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: var(--xxl);
  }
`

export const MenuGroup = styled.div`
  border-top: 3px solid var(--beau);
  margin-top: var(--xl);
  h3 {
    margin-bottom: 0;
  }
  ul {
    list-style: none;
    margin: 0;
    li p {
      font-style: italic;
      margin-bottom: 0;
    }
    ul {
      display: flex;
      font-size: var(--sm);
      margin-bottom: 0;
      span {
        font-size: var(--md);
        font-family: var(--bebas);
      }
      li:not(:last-of-type) {
        margin-right: var(--sm);
      }
    }
  }
`
