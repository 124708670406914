import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Menu from '../components/Menu'

const MenuPage = () => (
  <Layout>
    <SEO title="Menu" />
    <h1>Our Menu</h1>
    <p>
      Please <Link to="/find-us">get in touch</Link> if you have any questions
      regarding our menu. We look forward to serving you.
    </p>
    <Menu />
  </Layout>
)

export default MenuPage
